@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Poppins:ital,wght@0,300;1,200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

* {
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: "Poppins" !important;
  /* overflow-x: hidden; */
}

#root {
  /* overflow: hidden; */
}

ul li span {
  color: #37a1ba;
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 1px;
}

.features ul li,
.Additional-Features ul li {
  line-height: 25px !important;
  padding: 5px 0;
  text-align: justify;
  font-size: 15px;
  color: #434343;
  font-weight: 400;
  font-style: normal;

}

/* demo */
/* Offer - Timer */
.offer-timer {
  display: flex;
  flex-wrap: wrap;
  background-color: #37a1ba;
  justify-content: center;
  margin: 0;
  padding: 0;
  z-index: 1000;
}

.offer-timer p {
  margin: 0;
  color: #fff;
  font-size: 12px;
  padding: 7px 0;
}

.offer-timer button {
  color: #000;
  background-color: #fff;
  text-decoration: none;
  font-size: 13px;
  font-style: normal;
  padding: 3px 11px;
  border-radius: 3px;
  font-weight: 400;
  border: none;
  margin: 3px 0;
  cursor: pointer;
}

.offer-timer button:hover {
  background-color: #d03b75;
  color: #fff;
  transition: 0.3s linear;
}

.containered12 {
  color: #333;
  text-align: center;
}

.containered12 h1 {
  font-weight: normal;
  letter-spacing: .125rem;
  text-transform: uppercase;
}

.containered12 li {
  display: inline-block;
  padding-top: 0px;
  font-size: 10px;
  color: #fff;
}

#countdown ul {
  padding: 4px 0;
  margin: 0;
}

.containered12 li span {
  display: block;
  color: #fff;
  font-weight: 900;
  padding: 0 14px;
  border-radius: 3px;
  font-size: 12px;
}

#countdown {
  color: #fff;
  display: inline-block;
  font-weight: 100;
  text-align: center;
  font-size: 16px;
  background-color: #434343;
  line-height: 11px;
  margin: 0 6%;
  height: 31px;
  width: 110%;
}

@media all and (max-width: 768px) {
  .containered12 h1 {
    font-size: calc(1.5rem * var(--smaller));
  }

  .containered12 li {
    font-size: calc(1.125rem * var(--smaller));
  }

  .containered12 li span {
    font-size: calc(3.375rem * var(--smaller));
  }
}

/* Timer Start*/

#clockdiv {

  margin: 0 6%;
}

#clockdiv>div {}

#countdown ul .borderright {
  border-right: 1px solid #fff;
}

#clockdiv div>span {
  font-weight: 900;
  padding: 0 14px;
  border-radius: 3px;
  display: inline-block;
  font-size: 12px;
}

#clockdiv .border-right {
  border-right: 1px solid #fff !important;
}

.smalltext {
  padding-top: 0px;
  font-size: 10px;
}

/* Timer End */
/* Offer - Timer - End*/

/* Navbar-Section-Start */
.Homepage .nav-item .nav-link.active {
  color: #32bba1;
}

.Navigationbar {
  background-color: #fff;
  z-index: 1000;
  position: sticky;
  top: 0;
}

.navigation .container {
  padding: 0;
}

.navigation {
  background-color: #fff;
  z-index: 1000;
}

.navbar-brand img {
  width: 70%;
  margin: 0;
}

.navbar .navbar-nav .nav-link:hover {
  color: #000000;
}

.navbar .navbar-nav .nav-link {
  color: #000000;
  font-size: 1.1em;
}

@media only screen and (min-width: 992px) {
  .navbar {
    padding: 0;
  }

  .navbar .navbar-brand {
    padding: 0 0.7em;
  }

  .navbar .navbar-nav .nav-link {
    padding: 1em 0;
  }

  .navbar .navbar-nav .nav-item {
    margin: 0 1em;
  }
}

.navbar .navbar-nav .nav-item {
  position: relative;
}

.navbar .navbar-nav .nav-item::after {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 28px;
  right: 0;
  margin: auto;
  width: 4px;
  height: 4px;
  background-color: #32bba1;
  height: 4px;
  content: "";
  border-radius: 50%;
}

.navbar .navbar-nav .nav-item::after {
  transition: all 0.5s;
}

.navbar-expand-lg .navbar-collapse {
  padding-left: 22%;
}

.navbar-collapse a button {
  padding-left: 19%;
  font-weight: 600;
  background-image: linear-gradient(to right,
      rgb(50, 184, 164),
      rgb(55, 165, 183));
  border: none;
  color: #fff;
  padding: 11px 20px;
  font-size: 18px;
  border-radius: 6px;
  margin-left: 1rem;
}

.navbar-collapse a button:hover {
  background: radial-gradient(circle at 24.1% 68.8%,
      rgb(50, 50, 50) 0%,
      rgb(0, 0, 0) 99.4%);
  animation: all 0.5s ease-in-out;
}

/* underline */
.navbar .navbar-nav .nav-link {
  padding: 0;
  font-weight: 500;
  font-size: 18px;
}

.navbar .navbar-nav .nav-link:hover {
  color: #32bba1;
}

.collapse ul li a {
  position: relative;
  color: #000;
  text-decoration: none;
}

.collapse ul li a:hover {
  color: #000;
}

.collapse ul li a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  top: 26px;
  height: 4px;
  border-radius: 20px;
  bottom: 0;
  color: #00c7ee;
  left: 0;
  background-color: #32bba1;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.collapse ul li a:hover::before {
  transform: scaleX(1);
}

.collapse ul li .nav-link.m-active::before {
  transform: scaleX(1);
}

.home-navbar .navbar-nav .nav-link.m-active {
  /* font-size: 16px; */
  color: #32bba1;
  /* font-weight: bold; */
}

.type {
  color: #0b0086;
}

.online-store {
  overflow: hidden;
  background-image: url("../src/images/weegetz home banner 2.png");
  background-repeat: no-repeat;
  padding: 1% 0;
  background-size: 100%, cover;
  display: flex;
  justify-content: center;
  padding: 7% 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 90.5vh;
}

.online-store .container {
  margin: 0;
}

.online-store .content {
  color: #fff;
}

.online-store .content h6 {
  font-size: 24px;
}

.online-store .content h1 {
  font-size: 27px;
  font-weight: 600;
  line-height: 33px;
  font-style: normal;
  padding: 60px 0;
  text-align: justify;
  font-family: 'gilroy-extrabold', sans-serif;
  width: 88%;
}

.online-store .content h1 {
  font-size: 27px;
  font-weight: 400;
  line-height: 40px;
  font-style: normal;
  padding: 45px 0;
  text-align: justify;
  /* font-family: 'gilroy-extrabold', sans-serif; */
  width: 88%;
}

.online-store .content h5 {
  font-size: 24px;
  padding-top: 10px;
  line-height: 28px;
}

.shoping-trolly img {
  width: 100%;
  margin: 0;
  /* padding: 40px 40px 0 0; */
}

.online-store .store {
  /* padding: 2% 0 0 10%; */
  padding-top: 2%;
  display: flex;
  flex-wrap: wrap;
}

.online-store .store a {
  cursor: pointer;
}

.online-store .store a:nth-child(1) {
  background-color: #000;
  color: #fff;
  border-radius: 6px;
  display: flex;
  padding: 13px 29px;
  margin: 5px;
  text-decoration: none;
}

.online-store .store a img:nth-child(1),
.online-store .store a img:nth-child(2),
.online-store .store a img:nth-child(3) {
  width: 25px;
  margin: 0;
  height: 25px;
}

.online-store .store a:nth-child(2) {
  background-color: #000;
  color: #fff;
  padding: 12px 38px;
  border-radius: 6px;
  display: flex;
  margin: 5px;
  text-decoration: none;
}

.online-store .store a:nth-child(3) {
  background-color: #000;
  color: #fff;
  border-radius: 6px;
  padding: 13px 44px;
  display: flex;
  margin: 5px;
  text-decoration: none;
}

.online-store .store a span {
  font-size: 18px;
  padding-left: 5px;
}

/* video play */

/* Layout for this demo */
.wrap {
  position: relative;
  max-width: 1000px;
  width: 90%;
  margin: 100px auto;
}

/* The outer-div to move the elements */
.box-video {
  position: relative;
  width: 100%;
  margin: 0 auto 20px auto;
  cursor: pointer;
  overflow: hidden;
}

/* Set Cover aka Background-Image */
.box-video .bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 2;
}

/* Add light shade to make play button visible*/
.bg-video::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 3;
}

/* The Play-Button using CSS-Only */
.box-video .bt-play {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  display: inline-block;
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.1);

  border-radius: 50%;
  text-indent: -999em;
  cursor: pointer;
  z-index: 2;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/* The Play-Triangle */
.box-video .bt-play:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  height: 0;
  width: 0;
  margin: -12px 0 0 -6px;
  border: solid transparent;
  border-left-color: #000;
  border-width: 12px 20px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.box-video:hover .bt-play {
  transform: scale(1.1);
}

/* When Class added the Cover gets hidden... */
.box-video.open .bg-video {
  visibility: hidden;
  opacity: 0;

  -webkit-transition: all 0.6s 0.8s;
  transition: all 0.6s 0.8s;
}

/* and iframe shows up */
.box-video.open .video-container {
  opacity: 1;
  -webkit-transition: all 0.6s 0.8s;
  transition: all 0.6s 0.8s;
}

/* Giving the div ratio of 16:9 with padding */
.video-container {
  position: relative;
  width: 100%;
  height: 0;
  margin: 0;
  z-index: 1;
  padding-bottom: 56.27198%;
}

.shoping-trolly img {
  display: none;
}

.shoping-trolly video {
  border-radius: 15px;
}

.play-video {
  display: flex;
  align-items: center;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
}

/* video play End */
/* Online Store Start */

/* Weegetz-Store Start*/

.weegetz-store {
  overflow: hidden;
  background-color: #f4fcfa;
  padding: 3% 0;
}

.wee-store {
  display: flex;
  align-items: center;
}

.weegetz-store .left-content h1 {
  color: #434343;
  letter-spacing: 3px;
  font-size: 39px;
  font-weight: 900;
}

.weegetz-store .left-content h1 span {
  color: #32bba1;
}

.weegetz-store .left-content .head-1 {
  font-style: normal;
  font-size: 15px;
  padding-top: 12px;
  font-weight: 400;
  line-height: 29px;
  color: #434343;
  text-align: justify;
}

.weegetz-store .left-content .head-2 {
  /* font-style: oblique; */
  font-weight: 900;
  padding-top: 20px;
  font-size: 20px;
  letter-spacing: 1px;
  color: #434343;
  color: #32bba1;
  margin: 0;
}

.weegetz-store .right-img img {
  width: 100%;
  padding: 0px 0 0 5rem;
}

/* Weegetz-Store Start*/
/* Value Start */

.value .title {
  font-size: 60px;
  padding: 20px 0px;
}

.value .rowed {
  display: flex;
  flex-wrap: wrap;
  margin: 0 30px;
}

.value .card {
  width: 18%;
  margin: 10px auto;
}

.value .front {
  padding: 20px 10px;
}

.value .front img {
  width: 60%;
  display: flex;
}

.value .front h2 {
  font-size: 14px;
  font-weight: bold;
  color: #434343;
  text-align: start;
  padding-top: 6%;
  line-height: 35px;
  font-family: 'Poppins';
}

/* Float three columns side by side */

/* Remove extra left and right margins, due to padding */

/* Clear floats after the columns */

/* Style the counter cards */
.value .card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 5px;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
  border-left: 6px solid #32bba1;
}

.value .card .profile {
  border-radius: 10px;
  transition: 0.2s;
}

.value .card:hover .profile {
  transform: scale(1.4);
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
}

.value .card .content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.value .card .content .back {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f1f1f1e6;
  background-color: #32bba1;
  transition: 1s;
  z-index: 1;
  padding: 10px;
  display: flex;
  align-items: center;
}

.value .from-left {
  top: 0;
  left: -100%;
}

.value .card:hover .content .from-left {
  left: 0%;
}

.value .from-bottom {
  top: 100%;
  left: 0;
}

.value .card:hover .content .from-bottom {
  top: 0%;
}

.value .from-right {
  top: 0%;
  right: -100%;
}

.value .card:hover .content .from-right {
  right: 0;
}

.value .card .content .back h3 {
  font-size: 15px;
  letter-spacing: 2px;
  font-family: 'Poppins';
}

.value .card .content .back .tem-img {
  border-radius: 100%;
}

.value .card .content .back .des {
  font-size: 10px;
  padding: 0px 0px;
  text-align: justify;
  line-height: 30px;
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  font-weight: 700;
}

.value .card .content .back .social-icon {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.value .card .content .back .social-icon li {
  display: inline-block;
}

.value .card .content .back .social-icon li a {
  display: block;
  background: #333;
  color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 100%;

}

.value {
  overflow: hidden;
  padding: 3% 0;
  text-align: center;
}

.value .d-flex {
  flex-wrap: wrap;
}

.value h1 {
  font-size: 39px;
  padding-top: 14px;
  letter-spacing: 1px;
  color: #32bba1;
  font-weight: 900;
}

.value h6 {
  font-size: 18px;
  padding: 1% 0;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.value .row {
  margin-top: 3%;
}

.value .d-flex .flex-fill {
  margin: 0 10px;
}

.value .d-flex .adjust .card {
  padding: 42px 0 49px 24px;
}

.value .flex-fill .card .known {
  font-size: 13px;
  padding-top: 15%;
  font-weight: 600;
  line-height: 24px;
  text-align: start;
}

.value .flex-fill .card .knowns {
  margin-bottom: 0px;
}

.value .flex-fill .card .knowns-1 {
  margin-bottom: 8px;
}

.value .flex-fill .card img {
  width: 35%;
  background-color: #edf9f7;
  padding: 15px;
  border-radius: 6px;
}

/* hover */

.flip-card {
  background-color: transparent;
  width: 230px;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;

  transition: transform 0.6s;
  -moz-transition: transform 0.6s;
  -o-transition: transform 0.6s;
  -webkit-transition: transform 0.6s;

  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform 1s;
  border-radius: 15px;
  margin: 10% 0;
}

.flip-card:focus {
  outline: 0;
}

.flip-card:hover .flip-card-inner,
.flip-card:focus .flip-card-inner {
  transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background: linear-gradient(to left, #ffffff, #ffffff);
  color: black;
  z-index: 2;
  padding: 18px 0 0 18px;
  border-radius: 15px;
  border-left: 8px solid #32bba1;
}

.flip-card-back {
  background: linear-gradient(to right, #ffffff, #ffffff);
  color: rgb(0, 0, 0);
  transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -webkit-transition: -webkit-transform 1s;
  z-index: 1;
  border-radius: 15px;
  border-left: 8px solid #32bba1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.flip-card-back .back-text {
  text-align: justify;
  padding-right: 13px;
  font-size: 11px;
}

.flip-card-front img {
  padding: 0 0 0 0;
  max-width: 60%;
}

.flip-card p {
  font-size: 14px;
  font-weight: bold;
  color: #434343;
  text-align: start;
  padding-top: 6%;
  line-height: 35px;
}

/* hover End */
/* Value End */
/* Features Start */
.bottom-heading-1{
  display: flex;
    justify-content: center;
    font-size: 18px;
}
.bottom-heading{
  display: flex;
    justify-content: center;
    font-size: 18px;
    margin: 0 0 0 63%;
}
.bottom-heading-12{
  margin: 0 0 0 14%;

}
.bottom-heading-13{
  margin: 0 0 0 19%;

}
.bottom-heading-14{
  margin: 0 0 0 63%;

}
.bottom-heading-15{
  display: flex;
  justify-content: start;
  margin: 0 0 0 25%;

}
.bottom-heading16{}
.features {
  overflow: hidden;
  text-align: center;
  background-color: #f5f5f5;
  padding: 3%;
}

.feature-img-1 {
  display: none;
}

.features-img-1 {
  display: flex;
  align-items: center;
}

.feature-img-2 {
  display: none;
}

.features-img-2 {
  display: flex;
  align-items: center;
}

.feature-img-3 {
  display: none;
}

.features-img-3 {
  display: flex;
  align-items: center;
}

.feature-img-4 {
  display: none;
}

.features-img-4 {
  display: flex;
  align-items: center;
}

.feature-img-5 {
  display: none;
}

.features-img-5 {
  display: flex;
  align-items: center;
}

.feature-img-6 {
  display: none;
}

.features-img-6 {
  display: flex;
  align-items: center;
}

.head-features {
  background-color: #f5fafc;
}

.features h1 {
  font-weight: 700;
  font-size: 40px;
}

.features h3 {
  background-color: #37a1ba;
  color: #fff;
  display: inline-block;
  margin: 12px 0;
  padding: 12px 23px;
  border-radius: 30px;
}

.features .left-img img {
  width: 100%;
  padding: 0% 46px 0 16px;
}

.features .right-content {
  text-align: start;
}

.heading {
  color: #37a1ba;
  font-size: 24px;
}

.heading-para {
  font-size: 18px;
  line-height: 25px;
  color: #434343;
}

.head-features .background-line-1 .row {
  align-items: center;
  padding: 3% 0;
}

.features .right-content h1 {
  padding-top: 0%;
  font-size: 24px;
}

.features .right-content p {
  margin: 0;
  color: #434343;
  line-height: 25px;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.features .right-content ul {
  padding: 11px 0 0 21px;
  line-height: 37px;
}

.features .right-content ul li {
  font-size: 15px;
  color: #434343;
  line-height: 28px;
  font-weight: 400;
  font-style: normal;
}

.Customers ul li::marker,
.weegetz-store .left-content ul li::marker,
.features .right-content ul li::marker {
  font-size: 24px;
  color: #37a1ba;
}

.weegetz-store .left-content ul li::marker
 {
  font-size: 24px;
  color: #32bba1;
}

/* Predictive Analytics Start*/
.Predictive-Analytics {
  text-align: start;
}

.Predictive-Analytics .left-text {}

.Predictive-Analytics .left-text h1 {
  color: #37a1ba;
  font-size: 24px;
}

.Predictive-Analytics ul li::marker {
  font-size: 24px;
  color: #37a1ba;
}

.Predictive-Analytics .left-text p {
  text-align: justify;
  color: #434343;

  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.Predictive-Analytics .left-text ul li {
  padding-top: 11px;
  text-align: justify;

  font-size: 15px;
  color: #434343;
  line-height: 28px;
  font-weight: 400;
  font-style: normal;
}

.Predictive-Analytics .right-img img {
  width: 100%;
  padding: 0% 16px 0 46px;
}

/*Predictive Analytics End  */

/* Multi-store Integration Start*/
.Multi-store-integration .right-img img {
  width: 100%;
  padding: 0% 46px 0 16px;
}

.Multi-store-integration .left-text {
  text-align: start;
}

.Multi-store-integration .left-text h1 {
  color: #37a1ba;
  font-size: 24px;
}

.Multi-store-integration .left-text p {
  padding-top: 8px;
  text-align: justify;
  line-height: 25px;
  margin: 0;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.Multi-store-integration .left-text ul {
  padding-left: 21px;
}

.Multi-store-integration .left-text ul li {
  font-size: 14px;
  line-height: 36px;
  color: #434343;
  line-height: 28px;
  font-weight: 400;
  font-style: normal;
}

.Multi-store-integration .left-text ul li {
  font-size: 15px;
  color: #434343;
  line-height: 28px;
  font-weight: 400;
  font-style: normal;
}


.Multi-store-integration .left-text ul li::marker {
  font-size: 24px;
  color: #37a1ba;
}

/* Multi-store Integration End */
/* Customer loyalty programs Start */
.Customer-loyalty-programs .left-text {
  text-align: start;
}

.Customer-loyalty-programs .left-text h1 {
  padding-top: 2%;
  font-size: 24px;
}

.Customer-loyalty-programs .left-text p {
  line-height: 25px;
  margin: 0;
  text-align: justify;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.Customer-loyalty-programs .left-text ul {
  padding-left: 21px;
  padding-top: 1%;
}

.Customer-loyalty-programs .left-text li {

  text-align: justify;
  font-size: 15px;
  color: #434343;
  line-height: 28px;
  font-weight: 400;
  font-style: normal;
}

.Customer-loyalty-programs .left-text ul li::marker {
  font-size: 24px;
  color: #37a1ba;
}

.Customer-loyalty-programs .right-img img {
  width: 100%;
  padding: 0% 16px 0 46px;
}

/* Customer loyalty programs End */
/* B2C ( Customers) Start*/
.Customers {
  /* margin-top: 4%; */
}

.Customers h3 {
  text-align: center;
  background-color: #37a1ba;
  color: #fff;
  display: inline-block;
  margin: 12px 0 0 0;
  padding: 14px 23px;
  border-radius: 30px;
}

/* Shop-and-Earn Start*/
.Shop-and-Earn {
  padding-top: 0%;
}

.Shop-and-Earn .row {
  align-items: center;
}

.Shop-and-Earn .left-text {
  text-align: justify;
}

.Shop-and-Earn .left-text h1 {
  color: #37a1ba;
  font-size: 20px;
  font-weight: 700;
}

.Shop-and-Earn .left-text ul li::marker {
  font-size: 24px;
  color: #37a1ba;
}

.Shop-and-Earn .left-text ul li {

  font-size: 14px;
  color: #434343;
  line-height: 28px;
  font-weight: 400;
  font-style: normal;
}

.Shop-and-Earn .left-text p {
  margin-top: 16px;
  line-height: 25px;
  color: #434343;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.Shop-and-Earn .right-img img {
  width: 100%;
  padding: 0px 16px 0px 46px;
}

/* Shop-and-Earn End*/
/* Grab and Go */
.Grab-and-Go .left-text {
  text-align: start;
  margin-top: 78px;
}

.Grab-and-Go .left-text h1 {
  font-weight: 700;
  font-size: 24px;
  margin-top: 14px;
}

.Grab-and-Go .left-text p {
  margin-top: 16px;
  line-height: 25px;
  color: #434343;
  text-align: justify;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.Grab-and-Go {
  padding-top: 3%;
  align-items: center;
}

.Grab-and-Go .row {
  align-items: center;
}

.Grab-and-Go .right-img img {
  width: 100%;
  padding: 0px 16px 0px 46px;
}

/* Grab and Go */
/* Additional Features Start*/
.Additional-Features {
  overflow: hidden;
  text-align: center;
  background-color: #f4fcfa;
  padding: 3%;
}

.Additional-Features .row {
  padding-bottom: 3%;
}

.Additional-Features h2 {
  padding-bottom: 3%;
}

.Additional-Features .left-text p {
  color: #434343;

  font-weight: 600;
  text-align: justify;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}

.Additional-Features h2 {
  font-size: 24px;
  font-family: "Poppins";
  font-weight: 400;
  background-color: #37a1ba;
  color: #fff;
  display: inline-block;
  margin: 20px 0;
  padding: 12px 23px;
  border-radius: 30px;
}

.Additional-Features .left-text {
  text-align: start;
}

.Additional-Features .left-text h1 {
  padding-top: 0%;
  font-weight: 700;
}

.Additional-Features .left-text ul {
  padding-left: 4%;
}

.Additional-Features .left-text li {

  text-align: justify;
  font-size: 15px;
  color: #434343;
  line-height: 28px;
  font-weight: 400;
  font-style: normal;
}

.Additional-Features .left-text ul li::marker {
  font-size: 24px;
  color: #37a1ba;
}

.Additional-Features .right-img img {
  width: 100%;
  padding: 0% 16px 0 46px;
}

/* Sales-Report Start */
.Sales-report .right-img img {
  padding: 0% 26px;
}

/* Sales-Report End */
/* More-informative Start */
.More-informative {
  align-items: center;
}

.More-informative .right-img img {
  padding: 0% 16px 0 46px;
}

.More-informative .left-text p {
  color: #434343;
}

.right-img-second-final img {
  padding: 0% 46px 0 16px;
}

.right-img-final img {
  padding: 0% 16px 0 46px;
}

/* More-informative End*/

/* Additional Features End */

/* B2C ( Customers) End*/

/* Features End */

/* WEB View Start */
.web-view {
  overflow: hidden;
  text-align: center;
  padding: 3% 0;
  background-color: #00000017;
}

.web-view h1 {
  font-weight: 700;
}

.web-view h6 {
  font-size: 18px;
  padding: 0;
  color: #434343;
  font-weight: 400;
}

.lap-view {
  background-image: url("../src/images/5850.png");
  background-repeat: no-repeat;
  background-position: 50% 0;
  padding-top: 5%;
  height: 116vh;
  background-size: 100%;
}

/* web carousel Start*/
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}

.w-100 {
  max-width: 80% !important;
  margin: 20px auto;
  height: auto;
}

.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
  width: 50%;
  margin: auto;
}

.carousel-control-prev {
  right: 0;
  background-color: #32bba1;
  width: 50px;
  border-radius: 50px;
  height: 50px;
  position: absolute;
  top: 18rem;
}

.carousel-control-next {
  right: 0;
  background-color: #32bba1;
  width: 50px;
  border-radius: 50px;
  height: 50px;
  position: absolute;
  top: 18rem;
}

/* web carousel End*/

/* WEB View End */
/* App View Start */
.app-view {
  overflow: hidden;
  text-align: center;
  margin-top: 4%;
}

.app-view h1 {
  font-weight: 700;
}

.app-view h6 {
  font-size: 18px;
  padding: 0;
  color: #434343;
  font-weight: 400;
}

/* .mobile-view{
  display: flex;
} */
.mobile-view-1 {
  background-image: url("../src/images/Group_4324-removebg-preview.png");
  background-repeat: no-repeat;
  background-size: 85%;
  height: auto;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20% 0;
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  position: relative;
  /* z-index: -2; */
}

/* App Carousel Start */
.item {
  margin: 0 30px;
}

.image-wrap {
  width: 74%;
  overflow: hidden;
  height: 28rem;
  padding: 5% 0 0 0;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.image-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  animation: 15s infinite;
  transition: all 1s ease;
  padding: 0;
  margin: 0;
}

.image-wrap:hover img {
  object-position: bottom center;
}

/* App View End*/

/* Pricing Start */
.pricing {
  overflow: hidden;
  text-align: center;
  padding: 1% 0;
  background-color: #0000000f;
}

.owl-carousel .owl-nav button.owl-next span {
  position: absolute;
  bottom: 42vh;
  right: -50px;
  background-color: #32bba1;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
}

.owl-carousel .owl-nav button.owl-prev span {
  position: absolute;
  bottom: 42vh;
  left: -50px;
  background-color: #32bba1;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
}

.pricing h1 {
  padding: 2% 0;
  font-weight: 700;
}

.pricing .seprate {
  margin: 0 2%;
}

.pricing .price-card {
  background-color: #fff;
  border-radius: 25px;
  margin: 20px 0;
  height: auto;
  padding: 0 0 0 22px;
  text-align: start;
  box-shadow: 0px 3px 6px 1px #ccc;
}

.pricing .price-card .goldTick {
  background-image: url("../src/images/tick.png");
  width: 30px;
  height: 30px;
  background-size: contain;
}

.pricing .price-card .pricing-adjustment-1 span:hover,
.pricing .price-card:hover {
  background-image: linear-gradient(to bottom, #32bba1, #37a1ba);
  color: #fff;
}

.price-card:hover>.pricing-adjustment-1 .goldTick {
  /* background-color: #000; */
  background-image: url("../src/images/whitetick.png");
}

.price-card:hover>.pricing-adjustment-2 .goldTick {
  /* background-color: #000; */
  background-image: url("../src/images/whitetick.png");
}

.price-card:hover>.pricing-adjustment-3 .goldTick {
  /* background-color: #000; */
  background-image: url("../src/images/whitetick.png");
}

.pricing .price-card:hover>.button-2 {
  background-image: linear-gradient(to right, #ffffff, #ffffff);
  color: #32bba1;
}

.pricing .price-card:hover>.button-1 {
  background-image: linear-gradient(to right, #ffffff, #ffffff);
  color: #32bba1;
}

.pricing .price-card:hover>.border-bottom img,
.pricing .price-card:hover>.borders-bottom img {
  background-image: linear-gradient(to right, #ffffff00, #ffffff00);
  color: #fff;
}

.pricing .price-card .button-2:hover,
.pricing .price-card .button-3:hover {
  background-image: linear-gradient(to right, #ffffff, #ffffff);
  color: #32bba1;
}

.pricing .price-card .button-1 {
  border: none;
  background-image: linear-gradient(to right, #32bba1, #37a1ba);
  color: #fff;
  font-weight: bold;
  font-size: 21px;
  padding: 5px 20px;
  border-radius: 8px;
  margin: 40px 0 0 0;
  cursor: pointer;
}

.pricing .price-card .button-4 {
  text-align: center;
  position: relative;
  left: 30px;
}

.pricing .price-card h1 {
  font-size: 44px;
  font-weight: 900;
  margin-top: 7%;
  line-height: 35px;
  text-align: center;
  margin: 7% 0 0 -50px;
}

.pricing .price-card h1 .offer-dolor {
  font-size: 28px;
  text-decoration: line-through;
  font-weight: 400;
  margin-left: 2%;
}

.pricing .price-card h1 span {
  font-size: 18px;
  font-weight: 400;
}

.pricing .price-card p {
  font-size: 15px;
  font-weight: 400;
  margin-top: 4%;
}

.pricing .price-card .border-bottom,
.pricing .price-card .borders-bottom {
  font-size: 13px;
  line-height: 41px;
  font-weight: 600;
  display: flex;
  margin: 0 0 0 20px;
  width: 87%;
}

.pricing .price-card .border-bottom img,
.pricing .price-card .borders-bottom img {
  width: 25px;
  height: 25px;
  margin: 0;
}

.pricing .price-card .border-bottom span,
.pricing .price-card .borders-bottom span {
  margin: 5px 10px 0 0px;
}

.pricing .pricing-adjustment-1 {
  padding: 3px 0;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
  width: 92%;
}

.pricing .price-card .button-2 {
  border: none;
  background-image: linear-gradient(to right, #32bba1, #37a1ba);
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  padding: 13px 39px;
  border-radius: 5px;
  margin: 7% auto;
  cursor: pointer;
}

.pricing .price-card .button-3 {
  padding: 13px 31%;
}

/* Pricing End */
/* About Us Start */
.AboutUs {
  overflow: hidden;
  background-image: url("../src/images/Rectangle 32648.jpg");
  background-repeat: no-repeat;
  text-align: center;
  padding: 4% 0;
  color: #fff;
}

.AboutUs h1 {
  font-weight: 700;
}

.AboutUs .content p {
  font-size: 16px;
  margin-top: 36px;
  line-height: 41px;
}

.AboutUs .icons {
  margin: 62px 0 0 0;
}

.AboutUs .icons a i {
  color: #fff;
  font-size: 29px;
  padding: 0 28px;
}

/* About Us End */
/*  */
.Contact-Us {
  background-color: #0000000f;
  padding: 3% 7%;
  overflow: hidden;
}

.contact-left-content {
  display: flex;
  align-items: center;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.Contact-Us .left-content {
  margin-top: 5%;
}

.Contact-Us .left-content h1 {
  font-size: 38px;
  font-weight: 900;
}

.Contact-Us .left-content h2 {
  font-size: 24px;
  font-weight: 900;
  font-family: inherit;
  margin-top: 20px;
}

.Contact-Us .left-content p {
  padding-top: 3%;
  line-height: 27px;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  margin: 0;
}

.Contact-Us .left-content .spreedsheet .first h1 {
  font-size: 16px;
  padding-top: 3%;
  font-weight: 600;
}

.Contact-Us .left-content .spreedsheet .first h1 span {
  color: #32bba1;
  position: relative;
  top: 6px;
  padding-right: 10px;
}

.Contact-Us .left-content .spreedsheet .first p {
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}

.Contact-Us .right-form {
  padding: 7%;
  position: relative;
  background-color: #fff;
  border-radius: 20px;
}

.google-inputs {
  position: relative;
}

.google-input {
  margin-top: 18px;
}

.google-input label {
  position: absolute;
  left: 20px;
  z-index: 0;
  top: -12px;
  font-size: 11px;
  font-weight: 700;
  width: max-content;
  transition: all 0.2s ease-in-out;
  background: #fff;
  color: var(--text-secondary);
  border-radius: 10px;
  pointer-events: none;
  padding: 3px 5px;
}

.google-input input {
  width: 100%;
  height: 45px;
  border-radius: 6px;
  border: 1px solid #ccc;
  padding: 0 4%;
}

.google-input textarea {
  resize: none;
  border-radius: 10px;
  color: var(--text-secondary);
  border: 1px solid #ccc;
  width: 100%;
  padding: 15px;
}

.right-form .form-btn {
  border: none;
  background-image: linear-gradient(to right, #32bba1, #37a1ba);
  color: #fff;
  margin: 34px 0 0 0;
  font-size: 20px;
  padding: 16px 35%;
  border-radius: 6px;
  width: 100%;
  font-weight: 600;
  cursor: pointer;
}

.right-form .form-btn:hover {
  border: none;
  background-image: linear-gradient(to right, #434343, #434343);
  transition: all 1s linear;
}

/* Frequently Asked Questions */
.Frequently-Asked-Questions {
  overflow: hidden;
  padding-top: 3%;
  background-color: #f5fcfa;
}

.Frequently-Asked-Questions .left-img img {
  padding: 5% 0% 0 0;
  width: 100%;
  margin: 0;
}

.Frequently-Asked-Questions .right-colapse h1 {
  font-size: 43px;
  margin-top: 5px;
  font-weight: 700;
}

/* Collapse Start */
.accordion-wrapper {
  margin-bottom: 2rem;
  box-shadow: var(--collapse-box-shadow);
  border: 3px solid var(--collapse-line-color);
  margin-top: 8%;
}

details {
  background: var(--collapse-bg);
  color: var(--collapse-color);
  width: 100%;
  border-bottom: 2px solid #ccc;
  display: inline-block;
}

details:not(:first-child) {
  border-top: 1px solid var(--collapse-line-color);
  line-height: 60px;
}

summary {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  cursor: pointer;
  list-style-type: none;
}

summary,
.details-info {
  font-size: 16px;
  font-weight: 700;
  padding: 4px 0px;
}

.details-info p {
  font-size: 11px;
  font-weight: 600;
  margin: 13px 0 0 0;
  padding: 0 50px 15px 0;
  line-height: 18px;
  color: #434343;
  text-align: justify;
}

summary .icon {
  transition: transform 0.26s;
  color: var(--collapse-icon-color);
  width: 50%;
}

.details-info {
  border-top: 1px dashed var(--collapse-line-color);
}

details[open] summary .icon {
  transform: rotate(45deg);
}

img {
  display: block;
  max-width: 100%;
  margin: 0 0 24px 0;
}

h2 {
  margin: 0 0 8px 0;
  font-family: "Merriweather", serif;
  font-size: 1.5rem;
}

a {
  color: darkorange;
}

.icon {
  display: inline-block;
  width: 40px;
  height: 40px;
}

@keyframes growAndFadeIn {
  0% {
    font-size: 0;
    opacity: 0;
  }

  90% {
    font-size: 1em;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Collapse End*/
/* Frequently Asked Questions */
/* Footer Start */
footer {
  background-color: #434343;
  padding: 3% 5%;
  color: #fff;
}

footer .left-content img {
  width: 16%;
  margin: 5px 0 0 20px;
}

footer .left-content h6 {
  padding: 9% 0 0 0px;
  font-size: 16px;
  font-weight: 400;
}

footer .left-content h5 {
  padding: 4% 0 0 0;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

footer .left-content h4 {
  padding-top: 8%;
  font-size: 16px;
  font-weight: 400;
}

footer .right-content {
  padding: 28% 0 0 0;
  text-align: end;
}

footer .right-content h6 {
  padding-top: 30%;
  font-size: 17px;
  font-weight: 400;
}

footer .right-content h5 {
  padding-top: 47%;
  font-size: 16px;
  font-weight: 400;
}

footer .right-content .icons a i {
  color: #fff;
  font-size: 26px;
  padding-left: 10%;
}

footer .right-content .icons a .fa-facebook:hover {
  color: #6a70ce;
}

footer .right-content .icons a .fa-instagram:hover {
  background-image: linear-gradient(to left,
      #8a3ab9,
      #e95950,
      #bc2a8d,
      #fccc63,
      #fbad50,
      #cd486b,
      #4c68d7);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}

footer .right-content .icons a .fa-twitter:hover {
  color: #00c7ee;
}

footer .right-content .icons a .fa-youtube:hover {
  color: #ff0000;
}

footer .right-content .icons a .fa-linkedin:hover {
  color: #0a66c2;
}

.legal-privacy-security {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  padding: 0;
}

.legal-privacy-security a {
  color: #fff;

  text-align: end;
}

.legal-privacy-security a:nth-child(1) {
  padding: 0 10px;
}

.legal-privacy-security a:nth-child(2) {
  padding: 0 10px;
}

.legal-privacy-security a:nth-child(3) {
  padding: 0 0 0 10px;
}

/* Footer End */
/* Whatsapp */
.whatsapp .float {
  position: fixed;
  width: 45px;
  height: 45px;
  bottom: 40px;
  right: 41px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
}

.whatsapp .my-float {
  margin-top: 8px;
}

.whatsapp a {
  position: fixed;
  bottom: 0%;
  right: 0%;
  z-index: 1;
}

.whatsapp a img {
  width: 50%;
}

/* Whatsapp */
/* Privacy */
.Security .offer-timer,
.Privacy .offer-timer {
  display: none;
}

.Privacy {
  background-color: #00000017;
  color: #434343;
}

.Security .nav-item,
.Privacy .nav-item {
  display: none;
}

.Security .navbar-expand-lg .navbar-collapse,
.Privacy .navbar-expand-lg .navbar-collapse {
  padding-left: 50%;
}

.privacy-head {
  font-family: sans-serif;
  padding: 1% 0;
}

.privacy-head h1 {
  font-family: sans-serif;
}

.privacy-head h2 {
  font-family: sans-serif;
}

.privacy-head p {
  font-family: sans-serif;
  text-align: justify;
}

/* Privacy End */
/* Security */
.Security {
  background-color: #00000017;
  color: #434343;
}

.security-head h1 {
  font-family: sans-serif;
  padding: 1% 0;
}

.security-head h2 {
  font-family: sans-serif;
  padding: 1% 0;
}

.security-head p {
  font-family: sans-serif;
  text-align: justify;
  padding: 1% 0;
}

/* Security End */
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  padding-top: 0%;
}

.modal-content {
  min-height: 200px;
  color: #fff;
  background-color: #00abad;
}

.modalHeader {
  background-color: #e5f7f7;
  color: #00abad;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.succ-failure {
  background-color: #ffffff;
  padding: 10px;
  color: #00abad;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.Modal-View {
  width: 35%;
}

.modal-footer {
  background-color: #f2f2f2;
  justify-content: space-between;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.modal-scroll-content {
  height: 56vh;
  overflow-y: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */

.modal-scroll-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.modal-scroll-content {
  -ms-overflow-style: none;
  /* IE and Edge */

  scrollbar-width: none;
  /* Firefox */
}

.modal-content .close-icon {
  font-size: 30px;
  position: absolute;
  right: -15px;
  top: -21px;
  font-weight: bold;
  color: #00abad;
  background-color: transparent;
  border-radius: 25px;
  border: none;
  padding: 5px;
  cursor: pointer;
  z-index: 100;
}

.thankyousubmit .font-weight-bold {
  font-weight: 700 !important;
  font-size: 20px;
}

/* modal end */
.accordionSpace {
  margin-top: 70px;
}

.accordion-item {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
}

.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: transparent;
  box-shadow: none;
}

.accordion-body p,
.accordion-header button {
  font-family: 'Poppins';
}

.accordion-button:not(.collapsed)::after {
  background-image: url(https://www.freepnglogos.com/uploads/plus-icon/plus-icon-plus-math-icon-download-icons-9.png);
  transform: rotate(-45deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url(https://www.freepnglogos.com/uploads/plus-icon/plus-icon-plus-math-icon-download-icons-9.png);
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
  font-weight: 700;
  font-family: monospace;
  font-size: 16px;
}

.accordion-body p {
  font-size: 12px;
  padding: 0 20% 0 0;
}